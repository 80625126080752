<template>
    <div>
        <v-navigation-drawer
            app
            v-model="sidemenu"
        >
            <template v-slot:prepend>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            {{ $store.state.COMPANY_NAME }}
                        </v-list-item-title>
                        <v-list-item-subtitle> Strefa administracyjna </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in filteredNavItems"
                    :key="item.title"
                    :to="item.to"
                    :exact="item.exact === true"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="px-4 py-2 text-caption grey--text text-center">
                    WK-CMS 3.0 / WK-Tamer {{ $store.getters["tamer/version"] }}
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="sidemenu = !sidemenu">
                <v-icon>{{ sidemenu ? "mdi-arrow-collapse-left" : "mdi-menu" }}</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <UserTile
                :user="user"
                tag="router-link"
                :to="{
                    name: 'my-account__main'
                }"
                details-key="role"
                hide-name-details
            />
            <v-btn
                icon
                small
                class="ml-2"
                title="Wyloguj"
                @click="$store.dispatch('auth/logOut')"
            >
                <v-icon :size="20">mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        class="pa-0 pa-md-3"
                        :key="$route.fullPath"
                    >
                        <slot />
                    </div>
                </transition>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            sidemenu: true,

            nav_items: [
                {
                    icon: "mdi-home",
                    title: "Dashboard",
                    to: {
                        name: "home"
                    },
                    required_permissions: [],
                    exact: true
                },
                {
                    icon: "mdi-folder-question",
                    title: "Quizy",
                    to: {
                        name: "quizzes__list"
                    },
                    required_permissions: "quizzes.list"
                },
                {
                    icon: "mdi-shopping",
                    title: "Standalone Orders",
                    to: {
                        name: "standalone-orders__list"
                    },
                    required_permissions: "standalone_checkout_sessions.list"
                },
                {
                    icon: "mdi-chart-gantt",
                    title: "Statystyki - Quiz",
                    to: {
                        name: "quizstats"
                    },
                    required_permissions: "quiz_sessions.list"
                },
                {
                    icon: "mdi-account-group",
                    title: "Użytkownicy",
                    to: {
                        name: "users__list"
                    },
                    required_permissions: "users.list"
                }
                // {
                //     icon: "mdi-snail",
                //     title: "Test komponentów",
                //     to: {
                //         name: "test"
                //     },
                //     required_permissions: []
                // }
            ]
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user || {};
        },
        filteredNavItems() {
            return this.nav_items.filter(item =>
                this.$store.getters["auth/userHasPermissions"](item.required_permissions)
            );
        }
    },

    mounted() {
        if (window.innerWidth < 600) {
            this.$nextTick(_ => {
                this.sidemenu = false;
            });
        }
    }
};
</script>
