import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pl from "vuetify/lib/locale/pl";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#64a286",
                secondary: "#ffbf52"
            }
        }
    },
    lang: {
        locales: {
            pl
        },
        current: "pl"
    }
});
