import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import E404 from "../views/Error404.vue";
import store from "../store";
import waitForMs from "../helpers/wait-for-ms";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },

    // AUTH ROUTES
    {
        path: "/auth/logowanie",
        name: "auth-login",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/login.vue")
    },
    {
        path: "/auth/rejestracja",
        name: "auth-register",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/register.vue")
    },
    {
        path: "/auth/nie-pamietam-hasla",
        name: "auth-password-reset-request",
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/auth/password-reset-request.vue")
    },
    {
        path: "/auth/resetowanie-hasla",
        name: "auth-password-reset",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/password-reset.vue")
    },

    // MY ACCOUNT
    {
        path: "/moje-konto",
        name: "my-account__main",
        component: () =>
            import(/* webpackChunkName: "my-account" */ "../views/my-account/index.vue")
    },

    // USERS MODULE
    {
        path: "/uzytkownicy",
        name: "users__list",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/index.vue")
    },
    {
        path: "/uzytkownicy/:id",
        name: "users__edit",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/edit.vue")
    },

    // QUIZZES MODULE
    {
        path: "/quizy",
        name: "quizzes__list",
        component: () => import(/* webpackChunkName: "quizzes" */ "../views/quizzes/index.vue")
    },
    {
        path: "/quizy/:id",
        name: "quizzes__edit",
        component: () => import(/* webpackChunkName: "quizzes" */ "../views/quizzes/edit.vue")
    },

    // STANDALONE ORDERS MODULE
    {
        path: "/standalone-orders",
        name: "standalone-orders__list",
        component: () =>
            import(
                /* webpackChunkName: "standalone-orders" */ "../views/standalone-orders/index.vue"
            )
    },

    // QUIZ STATS MODULE
    {
        path: "/statystyki-quiz",
        name: "quizstats",
        component: () => import(/* webpackChunkName: "quizstats" */ "../views/quiz-stats/index.vue")
    },

    // TEST
    {
        path: "/test",
        name: "test",
        component: () => import(/* webpackChunkName: "test" */ "../views/test/index.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

const UNPROTECTED_PATHS = [
    "/auth/rejestracja",
    "/auth/logowanie",
    "/auth/odblokowanie-konta",
    "/auth/resetowanie-hasla",
    "/auth/nie-pamietam-hasla"
];

router.beforeEach(async (to, from, next) => {
    // jeżeli apka się jeszcze nie zbootowała to grzecznie czekamy
    while (!store.state.app_booted) {
        await waitForMs(250);
    }

    // jeżeli użytkownik próbuje się dostać na zabezpieczony route to sprawdzamy jego uprawnienia - musi być tylko zalogowany i nie role == user, resztę załatwi UI i 401
    if (
        UNPROTECTED_PATHS.indexOf(to.path) == -1 &&
        (!store.getters["auth/isLoggedIn"] ||
            (store.getters["auth/isLoggedIn"] && store.getters["auth/userRole"] == "user"))
    ) {
        return next("/auth/logowanie?rback=" + encodeURIComponent(to.path));
    }

    return next();
});

export default router;
