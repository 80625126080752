<template>
    <v-app>
        <LayoutMessages />
        <component
            :is="layout"
            :key="layout"
        >
            <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
            />

            <v-overlay
                :value="!online_status"
                :opacity="0.75"
            >
                <div>
                    <div style="display: flex; justify-content: center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="info"
                        ></v-progress-circular>
                    </div>
                    <div class="text-center mt-4">
                        Aplikacja utraciła połączenie z internetem
                        <br />
                        Oczekiwanie na ponowne połączenie...
                    </div>
                </div>
            </v-overlay>
            <v-overlay
                :value="!appBooted"
                :opacity="0.75"
            >
                <div>
                    <div style="display: flex; justify-content: center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="secondary"
                        ></v-progress-circular>
                    </div>
                    <div class="text-center mt-4">Trwa ładowanie aplikacji...</div>
                </div>
            </v-overlay>
        </component>
    </v-app>
</template>

<script>
import LayoutMessages from "@/components/LayoutMessages";

export default {
    name: "App",

    components: {
        LayoutMessages
    },

    data: () => ({
        layout: "div",
        online_status: true
    }),

    computed: {
        appBooted() {
            return this.$store.state.app_booted;
        }
    },

    watch: {
        layout(nv, ov) {
            if (ov === "div") {
                this.$store.state.initial_layout_set = true;
            }
        }
    },

    methods: {
        // async testConnection(afterLost = false) {
        //     try {
        //         await this.$axios.$get("/test");
        //         if (afterLost)
        //             this.$store.dispatch("addMessage", {
        //                 type: "success",
        //                 msg: "Aplikacja odzyskała połączenie z internetem"
        //             });
        //         this.online_status = true;
        //     } catch (err) {
        //         console.error(err);

        //         this.online_status = false;
        //         setTimeout(() => {
        //             if (!this.online_status) this.testConnection(afterLost);
        //         }, 5000);
        //     }
        // },

        swOnOnline() {
            if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: `ONLINE_STATUS_CHANGE`,
                    online: true
                });
            }
        },
        swOnOffline() {
            if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: `ONLINE_STATUS_CHANGE`,
                    online: false
                });
            }
        }
    },

    async created() {
        /*******************************************
         * OBSŁUGA UTRATY I ODZYSKANIA POŁĄCZENIA *
         *******************************************/
        // this.testConnection();
        // window.addEventListener("online", () => {
        //     this.online_status = true;
        //     this.testConnection(true);
        // });
        // window.addEventListener("offline", () => {
        //     this.online_status = false;
        // });
    },

    metaInfo: {
        title: "Dashboard",
        titleTemplate: "%s | CMS Risify",

        htmlAttrs: {
            lang: "pl"
        }
    }
};
</script>
