import { io } from "socket.io-client";
import store from "../store";

import { playNewNotificationSound } from "../helpers/sounds";

export default {
    install(Vue) {
        Vue.prototype.$io = io(store.state.API_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false
        });

        Vue.prototype.$io.io.on("reconnect", async () => {
            console.log(`[Socket.io] Reconnected`);
            if (store.getters["auth/isLoggedIn"]) {
                await store.dispatch("auth/socketJoinRooms");
            }
        });
        Vue.prototype.$io.on("disconnect", async reason => {
            store.state.auth.socket_room_joined = false;
            console.log(`[Socket.io] Disconnected (${reason})`);
        });

        // CHAT EVENTS
        Vue.prototype.$io.on("Chat.created", chat => {
            chat.__from_socket_io = true;
            store.dispatch("chat/addChats", chat);
        });
        Vue.prototype.$io.on("Chat.updated", chat => {
            store.dispatch("chat/updateChat", chat);
        });
        Vue.prototype.$io.on("Chat.deleted", chat => {
            store.dispatch("chat/deleteChat", chat._id);
        });

        // CHAT MESSAGES
        Vue.prototype.$io.on("ChatMessage.created", async chat_message => {
            if (
                chat_message.author_type == "user" &&
                chat_message.user &&
                chat_message.user._id == store.state.auth.user._id
            ) {
                return; // na swoje nie reagujemy
            }

            store.dispatch("chat/addChatMessages", chat_message);

            const CHAT = store.getters["chat/findChat"](chat_message.chat);
            if (
                CHAT &&
                CHAT.assigned_user &&
                CHAT.assigned_user._id == store.state.auth.user._id &&
                chat_message.author_type == "customer"
            ) {
                try {
                    // oznaczenie nowododanej wiadomości jako dostarczonej
                    await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                        status: "delivered",
                        chat: chat_message.chat,
                        ids: [chat_message._id]
                    });

                    // jeżeli czat jest aktywny, to od razu też jako odczytaną
                    if (store.state.chat.active_chat == chat_message.chat) {
                        await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                            status: "read",
                            chat: chat_message.chat,
                            ids: [chat_message._id]
                        });
                    } else {
                        // jeżeli czat nie jest otwarty, to dajemy sygnał dźwiękowy
                        playNewNotificationSound();
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        });
        Vue.prototype.$io.on("ChatMessage.status_updated", async data => {
            // console.log(data);
            if (data.update_mode === "by_id") {
                for (let i = 0; i < data.ids.length; i++) {
                    store.dispatch("chat/updateChatMessage", {
                        _id: data.ids[i],
                        ...data.updated_data
                    });
                }
            } else if (data.update_mode === "by_c_date") {
                const CHM = store.getters["chat/getChatMessages"](data.chat);

                for (let i = 0; i < CHM.length; i++) {
                    // jeżeli zgadza się nam data oraz message_type, to jazda
                    if (
                        CHM[i].c_date <= data.to_date &&
                        data.updated_author_types.indexOf(CHM[i].author_type) !== -1 &&
                        ((CHM[i].status == "delivered" && data.updated_data.status == "read") ||
                            (CHM[i].status == "sent" && data.updated_data.status == "delivered") ||
                            (CHM[i].status == "created" && data.updated_data.status == "sent"))
                    ) {
                        store.dispatch("chat/updateChatMessage", {
                            _id: CHM[i]._id,
                            ...data.updated_data
                        });
                    }
                }
            }
        });
    }
};
