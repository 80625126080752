/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e101: "Nieprawidłowy lub nieważny token rejestracji. Skontaktuj się z Administratorem, aby wysłał do Ciebie nowe zaproszenie.",

        e201: "Nieprawidłowy e-mail lub hasło",
        e202: "Dokończ rejestrację, by móc się zalogować",
        e203: "Konto zostało zawieszone przez Administrację serwisu",
        e204: "Konto zostało tymczasowo zablokowane z powodu wielu nieudanych prób logowania. Spróbuj ponownie za kilka minut.",

        e301: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e401: "Nieprawidłowy lub nieważny token zmiany hasła.",

        e501: "Nieprawidłowy lub nieważny refresh_token"
    },

    users: {
        e201: "Istnieje już w systemie konto powiązane z podanym adresem e-mail",

        e502: "Wiadomość z zaproszeniem można wysłać raz na 5 minut",

        e602: "Nie możesz usunąć swojego konta",
        e603: "Podano nieprawidłowe hasło"
    },

    me: {
        e301: "Podano nieprawidłowe obecne hasło",

        e402: "Rozdzielczość przesłanego obrazu jest zbyt niska",
        e403: "Rozdzielczość przesłanego obrazu jest zbyt wysoka"
    },

    quizzes: {
        e402: "Istnieje już inny Quiz o takim adresie URL"
    },

    quiz_boards: {
        e502: "Nie można usunąć planszy będącej w użyciu"
    },

    mediamanager_folders: {
        e202: "W aktualnym katalogu istnieje już folder o takiej nazwie",
        e402: "W aktualnym katalogu istnieje już folder o takiej nazwie"
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
